import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import capabilityicon from "../../images/capability-crm-and-loyalty-2.png";

import clcsp from "../../images/clcsp.png";
import crmevaluation from "../../images/digital-audits.png";
import crmstrategy from "../../images/crm-strategy.png";
import crmimplementation from "../../images/crm-implementation.png";


import rowtwoheroleft from "../../images/crm-loyalty-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/crm-loyalty-row-two-hero-right.jpg";

import rowsixhero1 from "../../images/crm-loyalty-row-six-hero-1.png";
import rowsixhero2 from "../../images/crm-loyalty-row-six-hero-2.png";
import rowsixhero3 from "../../images/crm-loyalty-row-six-hero-3.png";
import rowsixhero4 from "../../images/crm-loyalty-row-six-hero-4.png";


import "../../scss/capabilitypage.scss";

const CrmLoyalty = ({ data }) => {
const metadescription = "Next&Co's CRWM and loyalty services provide our clients with increased customer lifetime value within the most profitable products and services they offer and improve digital experiences accountable to performance visit our site or call 1300 191 950";
const metatitle = "CRM and Loyalty Agency Melbourne, CRM Services - Next&Co";
const metaurl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
          <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription}/>
            <meta name="title" content={metatitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaurl} />
            <meta property="og:title" content={metatitle} />
            <meta property="og:description" content={metadescription} />
            <meta property="og:image" content="facebook.png" />
            <meta property="twitter:url" content={metaurl} />
            <meta property="twitter:title" content={metatitle} />
            <meta property="twitter:description" content={metadescription} />
            <meta property="twitter:image" content="twitter.png" />
        </Helmet>
        <Arrow/>
        <div className="capability-page-general crm-loyalty-page">
            <Layout>
		<CapabilitiesHeroSection
                  heading="CRM &amp; Loyalty"
                  description="CRM &amp; Loyalty – Helping increase customer lifetime value"
                  icon={capabilityicon}
                  imagedesktop={data.desktop.childImageSharp.fluid}
                  imagemobile={data.mobile.childImageSharp.fluid}
		/>
		<section id="row-three">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container row">
				<div className="col-one">
				    <h4 className="col-onef-heading">What we do</h4>
				</div>
				<div className="col-one">
				    <div className="list-items">
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={clcsp}/>
					    </span>
					    <div className="content">
						<h3>Customer lifecycle <br/> communications <br/> strategy &amp; <br/> production</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={crmevaluation} />
					    </span>
					    <div className="content">
						<h3>CRM evaluation</h3>
					    </div>
					</div>
				    </div>
				</div>
				<div className="col-one">
				    <div className="list-items">
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={crmstrategy} />
					    </span>
					    <div className="content">
						<h3>CRM strategy</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={crmimplementation} />
					    </span>
					    <div className="content">
						<h3>CRM implementation <br/> &amp; customisation</h3>
					    </div>
					</div>
				    </div>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<AuditButtons
                  title1="Let’s talk about your project."
                  linktitle1="Get in touch"
                  link1="#"

		/>
		<section id="row-two">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<h4>Building customer advocacy &amp; increasing lifetime value</h4>
				<p>Next&amp;Co provide a full service solution to helping a brand maximise customer lifetime value and advocacy.</p>
			    </div>
			    <div className="row">
				<div className="col">
				    <img alt="" src={rowtwoheroleft} />
				</div>
				<div className="col">
				    <img alt="" src={rowtwoheroright} />
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<section id="row-six">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<h4>Our approach</h4>
				<div className="row">
				    <div className="col">
					<img alt="" src={rowsixhero1} />
					<h5>Define customer journey</h5>
					<p>Define the current customer journey and each touchpoint a customer engages with your brand</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowsixhero2} />
					<h5>Audit</h5>
					<p>Assess the effectiveness of the messaging (or lack thereof) at each key customer moments and the tech that currently enables this</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowsixhero3} />
					<h5>Plan</h5>
					<p>Design an end-state customer lifecycle communication ecosystem</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowsixhero4} />
					<h5>Roadmap</h5>
					<p>Create a practical, projectised roadmap to take a brand from current-state to end-state</p>
				    </div>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<section id="row-four">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="col-one">
				    <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
				    <SingleButton buttonText="Get in touch"/>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<Audits />
            </Layout>
        </div>
    </>
  );
};

export default CrmLoyalty;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
